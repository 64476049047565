import http from "@/app/core/config/axios.config";

export default class CarteraService {
  /* GOBIERNO DATOS ----------------------------------------------------------------------------------------------------------- */

  static getCreditos(entry) {
    return http.post("/cartera/ConsultaCredito/estadocuenta", entry);
  }

  /* LEVANTA PRENDA ----------------------------------------------------------------------------------------------------------- */

  static getCreditosLevPrenda(entry) {
    return http.get(
      `/cartera/LevantaPrenda/ConsultaCreditoLevprenda?TipoDocumento=${entry.tipoDoc}&Identificacion=${entry.cedula}`
    );
  }

  static getCreditoLevPrenda(entry) {
    return http.get(`/cartera/LevantaPrenda/LevPrendaConsulta?Identificacion=${entry.cedula}&Credito=${entry.credito}`);
  }

  static procesarLevPrenda(entry) {
    return http.post("/cartera/LevantaPrenda/ProcesarLevantamiento", entry);
  }
}
