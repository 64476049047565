<template>
  <b-row>
    <template v-if="parametros !== null">
      <b-col cols="12">
        <p class="primary fs-24 fw-bold py-2">Paga tu Cuota</p>
        <p class="fs-14 text-justify">
          Elige el número de obligación y el valor por el cual deseas realizar el pago. Ten en cuenta que tus pagos se
          verán reflejados <span class="fw-bold">24 horas</span> después de aprobada la transacción. Tu pago debe estar
          entre {{ parametros.tope_minimo_pago | currency({ fractionCount: 0 }) }} y
          {{ parametros.tope_maximo_pago | currency({ fractionCount: 0 }) }}
        </p>
      </b-col>
      <b-col cols="12" md="6" class="mb-4">
        <b-card class="custom-card">
          <b-row>
            <b-col cols="12">
              <b-form-group :label="esPagoWeb ? 'Tus Obligaciones' : 'Número de Obligación'">
                <b-form-select v-model="creditoSelected">
                  <b-form-select-option :value="null">Seleccione número de obligación</b-form-select-option>
                  <template v-if="esPagoWeb">
                    <b-form-select-option
                      :value="credito"
                      :key="credito.numeroObligacion"
                      v-for="(credito, idx) in $store.getters.creditosActivos"
                    >
                      {{ `OBLIGACIÓN ${idx + 1}` }}
                    </b-form-select-option>
                  </template>
                  <template v-else>
                    <b-form-select-option
                      :value="credito"
                      :key="credito.numeroObligacion"
                      v-for="credito in $store.getters.creditosActivos"
                    >
                      {{ `Nro de Obligación ${credito.numeroObligacion} - ${credito.estado.toUpperCase()}` }}
                    </b-form-select-option>
                  </template>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" v-if="creditoSelected !== null">
              <b-form-group v-slot="{ valorPagoGroup }">
                <b-form-radio-group v-model="esOtroValor" :aria-describedby="valorPagoGroup">
                  <b-row>
                    <b-col cols="12" class="py-2 content-between-center fs-italic">
                      <label class="primary fs-14 mb-0">Saldo en Mora:</label>
                      <span class="flex-auto border-dashed mx-3 pt-2"></span>
                      <span class="fs-14 pr-4">{{ creditoSelected.saldoMora | currency }}</span>
                    </b-col>
                    <b-col cols="12" class="py-2 content-between-center fs-italic">
                      <label class="primary fs-14 mb-0">Próxima Cuota:</label>
                      <span class="flex-auto border-dashed mx-3 pt-2"></span>
                      <span class="fs-14 pr-4">{{ creditoSelected.proximaCuota | currency }}</span>
                    </b-col>
                    <b-col cols="12" class="py-2 content-between-center fw-bold">
                      <b-form-radio class="primary fs-14" :value="1">Pago Mínimo:</b-form-radio>
                      <label class="mb-0">
                        <span class="fs-14 pr-1">{{ creditoSelected.valorPago | currency }}</span>
                        <i id="popover-info" class="las la-question-circle fs-18 info cursor-pointer"></i>
                      </label>
                      <b-popover target="popover-info" placement="right" triggers="click" custom-class="bg-primary">
                        <strong class="text-white">
                          Este Valor incluye el saldo en mora, seguros, valores que se hayan generado por alguna novedad
                          en tu crédito y el valor de tu próxima cuota a vencer.
                        </strong>
                      </b-popover>
                    </b-col>
                    <b-col cols="12" class="py-2 content-between-center fw-bold">
                      <b-form-radio class="primary fs-14" :value="2">Pago Total:</b-form-radio>
                      <span class="fs-14 pr-4">{{ creditoSelected.saldoTotalFecha | currency }}</span>
                    </b-col>
                    <b-col cols="12" class="py-2 content-between-center fw-bold">
                      <b-form-radio class="primary fs-14" :value="3">Otro Valor:</b-form-radio>
                      <div v-if="esOtroValor === 3" class="pr-4">
                        <Money class="form-control text-right" v-model="formPC.valor" @keypress="onlyNumber" />
                      </div>
                    </b-col>
                  </b-row>
                </b-form-radio-group>
              </b-form-group>
            </b-col>
            <b-col v-if="esPagoWeb" cols="12" class="content-end-center">
              <b-button variant="danger" @click="realizarPago" :disabled="$v.$invalid || inhabilitarBtnPagar">
                <i class="las la-dollar-sign" /> Pagar
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" md="6" class="mb-4">
        <b-card :class="`custom-card ${esPagoWeb ? 'bg-custom-card' : ''}`">
          <b-row>
            <template v-if="esAbonoExtra && infoAbono === null">
              <b-col cols="12">
                <p class="fs-14">
                  Usted ha ingresado un monto mayor al pago mínimo. Selecciones que quiere hacer con el excedente.
                </p>
                <b-form-group v-slot="{ tipoAbonoGroup }">
                  <b-form-radio-group v-model="formPC.tipo_abono_extra" :aria-describedby="tipoAbonoGroup">
                    <b-row>
                      <b-col v-for="(item, idx) in parametros.tipos_abono_extra" :key="idx" cols="12" class="py-2">
                        <b-form-radio class="primary fs-14" :value="item.value">
                          <strong>{{ item.text }}</strong> {{ item.info }}
                        </b-form-radio>
                      </b-col>
                    </b-row>
                  </b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col cols="12" class="content-end-center">
                <b-button variant="danger" @click="validarTipoAbono(true)" :disabled="formPC.tipo_abono_extra === null">
                  <i class="las la-check-circle" /> Confirmar
                </b-button>
              </b-col>
            </template>
            <template v-else>
              <b-col cols="12" class="c-puntos-pago fs-14">
                <p v-if="abonoExtraV" class="primary mb-0">
                  Usted ha confirmado:
                  <label class="w-100">
                    <strong>{{ infoAbono.text }}</strong> {{ infoAbono.info }}
                  </label>
                </p>
                <template v-if="esPagoWeb">
                  <p class="fw-bold">{{ mensajesMetodosPago.mensaje1 }}</p>
                  <ul class="pl-4">
                    <li :key="idx" v-for="(item, idx) in mensajesMetodosPago.mensaje2" v-html="item.FmcMensaje"></li>
                  </ul>
                  <p class="fw-bold">{{ mensajesMetodosPago.mensaje3 }}</p>
                  <p v-html="mensajesMetodosPago.mensaje4"></p>
                  <p class="fw-bold">{{ mensajesMetodosPago.mensaje5 }}</p>
                  <ul class="pl-4">
                    <li :key="idx" v-for="(item, idx) in mensajesMetodosPago.mensaje6" v-html="item.FmcMensaje"></li>
                  </ul>
                </template>
                <template v-if="!esPagoWeb">
                  <p class="fs-14">Correo electrónico donde desea ser notificado</p>
                  <b-form-group v-slot="{ correoNotificacion }">
                    <b-form-radio-group v-model="esOtroCorreo" :aria-describedby="correoNotificacion">
                      <b-row>
                        <b-col cols="12" lg="4" class="py-2 content-between-center">
                          <b-form-radio class="primary fs-14" :value="0">Registrado:</b-form-radio>
                        </b-col>
                        <b-col cols="12" lg="8" class="py-2 content-end-center">
                          <span class="fw-bold fs-14">{{ $store.getters.correoElectronico }}</span>
                        </b-col>
                        <b-col cols="12" lg="4" class="py-2 content-between-center">
                          <b-form-radio class="primary fs-14" :value="1">Otros correos:</b-form-radio>
                        </b-col>
                        <b-col cols="12" lg="8" class="py-2">
                          <template v-if="esOtroCorreo === 1">
                            <b-form-tags
                              size="sm"
                              tag-pills
                              tag-variant="info"
                              limitTagsText="10"
                              addButtonText="Agregar"
                              :disableAddButton="true"
                              placeholder="Agregar correo"
                              :tag-validator="validarCorreo"
                              addButtonVariant="outline-info"
                              invalidTagText="Correo invalido"
                              duplicateTagText="Correo repetido"
                              v-model="formPC.correos_adicionales"
                            />
                          </template>
                        </b-col>
                      </b-row>
                    </b-form-radio-group>
                  </b-form-group>
                </template>
              </b-col>
              <b-col cols="12" class="content-end-center">
                <b-button v-if="abonoExtraV" variant="danger" class="mr-3" @click="validarTipoAbono(false)">
                  <i class="las la-arrow-left" /> Atras
                </b-button>
                <b-button
                  v-if="!esPagoWeb"
                  variant="danger"
                  @click="realizarPago"
                  :disabled="$v.$invalid || inhabilitarBtnPagar"
                >
                  <i class="las la-dollar-sign" /> Pagar
                </b-button>
              </b-col>
            </template>
          </b-row>
        </b-card>
      </b-col>
    </template>
    <b-form id="formPago" method="POST" hidden></b-form>
  </b-row>
</template>

<script>
import { Money } from "v-money";
import PagosService from "@/app/core/api/pagos";
import CarteraService from "@/app/core/api/cartera";
import mixinsEvents from "@/app/shared/mixins/mixins-events";
import AlertsService from "@/app/shared/services/alerts.service";
import { required, requiredIf, minValue, maxValue, minLength } from "vuelidate/lib/validators";

export default {
  mixins: [mixinsEvents],
  components: { Money },
  props: { esPagoWeb: { type: Boolean, default: false } },
  data() {
    return {
      infoAbono: null,
      parametros: null,
      mensajePagos: "",
      esOtroValor: null,
      esOtroCorreo: null,
      creditosActivos: [],
      mensajesMetodosPago: {},
      creditoSelected: null,
      inhabilitarBtnPagar: false,
      formPC: {
        valor: 0,
        genero: null,
        moneda: "COP",
        nombre_usuario: null,
        numero_producto: null,
        apellido_usuario: null,
        tipo_abono_extra: null,
        correos_adicionales: [],
        url_retorno_comercio: null,
        tipo_Transaccion: "CREDITO",
        url_notificacion_resultado: null,
        origen: this.esPagoWeb ? "PAGINA" : "SUCURSAL",
        direccion_usuario: this.$store.getters.direccion,
        identificacion: this.$store.getters.identificacion,
        celular_usuario: this.$store.getters.numeroCelular,
        correo_usuario: this.$store.getters.correoElectronico,
        id_Acceso: Number(this.$store.getters.idUltimoAcceso | 0),
      },
    };
  },
  watch: {
    creditoSelected() {
      const { primerNombre, segundoNombre, primerApellido, segundoApellido } = this.creditoSelected;
      this.formPC = {
        ...this.formPC,
        genero: this.creditoSelected.sexo,
        numero_producto: this.creditoSelected.numeroObligacion.toString(),
        nombre_usuario: `${this.validarVacio(primerNombre)} ${this.validarVacio(segundoNombre)}`,
        apellido_usuario: `${this.validarVacio(primerApellido)} ${this.validarVacio(segundoApellido)}`,
      };
    },
    esOtroValor(val) {
      this.esOtroCorreo = null;
      if (val === 1) this.formPC.valor = this.creditoSelected.valorPago;
      if (val === 2) this.formPC.valor = this.creditoSelected.saldoTotalFecha;
      if (val === 3) {
        this.formPC.valor = 0;
        this.infoAbono = null;
        this.formPC.tipo_abono_extra = null;
      }
    },
    esOtroCorreo(val) {
      if (val === 1) this.formPC.correo_usuario = "";
      else this.formPC.correo_usuario = this.$store.getters.correoElectronico;
    },
  },
  computed: {
    esAbonoExtra() {
      return this.esOtroValor === 3 && this.formPC.valor > this.creditoSelected.valorPago;
    },
    abonoExtraV() {
      return this.esAbonoExtra && this.infoAbono !== null;
    },
  },
  validations() {
    return {
      formPC: {
        numero_producto: { required },
        correos_adicionales: { required: requiredIf(() => this.esOtroCorreo === 1), minLength: minLength(1) },
        valor: {
          required,
          minValue: minValue(this.parametros.tope_minimo_pago),
          maxValue: maxValue(this.parametros.tope_maximo_pago),
        },
      },
      esOtroCorreo: { required: requiredIf(() => !this.esPagoWeb) },
    };
  },
  created() {
    if (this.esPagoWeb) {
      this.getParametrosPago();
      this.getMetodosPago();
      if (this.$store.getters.creditosActivos.length === 1)
        this.creditoSelected = this.$store.getters.creditosActivos[0];
    } else this.getCreditosActivos();
  },
  methods: {
    getParametrosPago() {
      PagosService.getParametrosPago().then((response) => {
        const parametros = {
          ...response.data,
          tipos_abono_extra: response.data.tipos_abono_extra.map((item) => ({ text: item.caption, value: item.value })),
        };
        this.$store.commit("setParametrosPago", parametros);
        this.parametros = this.$store.getters.parametrosPago;
        this.$store.commit("setIsGetParametrosPago", true);
        if (this.esPagoWeb) {
          const tiempo = response.data.TIEMPO_PERMANENCIA_PAGOS * 60000;
          setTimeout(() => {
            this.$store.commit("cleanUserData");
            this.$store.commit("setCreditosActivos", []);
            this.$store.commit("setParametrosPago", null);
            this.$store.commit("setIsGetParametrosPago", false);
            this.$router.replace("/validar-pago-web");
          }, tiempo);
        }
      });
    },
    ordenarMensajes(listaMensajes) {
      const newMenu = listaMensajes.map((elem) => elem);
      return newMenu.sort((elem1, elem2) => (elem1.FmcIdordenVineta > elem2.FmcIdordenVineta ? 1 : -1));
    },
    getMetodosPago() {
      PagosService.getMetodosPago().then((response) => {
        this.mensajePagos = response.data.mensajePagos;
        const mensajesPago = JSON.parse(response.data.mensajePagosImg);
        const mensaje1 = mensajesPago.find((x) => x.FmcIdorden === 1);
        const mensaje5 = mensajesPago.find((x) => x.FmcIdorden === 4);

        this.mensajesMetodosPago = {
          mensaje1: mensaje1.FmcMensaje,
          mensaje2: this.ordenarMensajes(mensajesPago.filter((x) => x.FmcIdpadre === mensaje1.FmcConsecutivo)),
          mensaje3: mensajesPago.find((x) => x.FmcIdorden === 2).FmcMensaje,
          mensaje4: mensajesPago.find((x) => x.FmcIdorden === 3).FmcMensaje,
          mensaje5: mensaje5.FmcMensaje,
          mensaje6: this.ordenarMensajes(mensajesPago.filter((x) => x.FmcIdpadre === mensaje5.FmcConsecutivo)),
        };
      });
    },
    getCreditosActivos() {
      const { tipoDocumento, identificacion } = this.$store.getters;
      const body = { tipoDocumento: tipoDocumento, numeroDocumento: parseInt(identificacion), pantalla: "Pagos" };

      CarteraService.getCreditos(body)
        .then((response) => {
          if (response.data.estado) {
            const creditosActivos = [];

            response.data.listaCreditos
              .filter((x) => x.estado !== "CANCELADO")
              .map((item) => {
                if (
                  item.edadCartera === 20 ||
                  item.diasEnMora > 0 ||
                  item.estado === "Activo" ||
                  item.estado === "VIGENTE" ||
                  item.estado === "MORA"
                ) {
                  creditosActivos.push({ ...item, estado: "ACTIVO" });
                }
              });

            this.$store.commit("setCreditosActivos", creditosActivos);

            if (creditosActivos.length === 0) {
              this.$router.push("/home");
              AlertsService.error("Pago de Cuota", "No tienes ningun credito activo.");
            } else {
              if (!this.$store.getters.isGetParametrosPago) this.getParametrosPago();
              else this.parametros = this.$store.getters.parametrosPago;
            }

            if (this.$store.getters.creditoSelected !== null) {
              this.creditoSelected = this.$store.getters.creditoSelected;
              return this.$store.commit("setCreditoSelected", null);
            }

            if (creditosActivos.length === 1) {
              this.creditoSelected = creditosActivos[0];
            }
          } else {
            AlertsService.error("Consulta de Creditos", response.data.mensaje);
          }
        })
        .catch(() => {
          AlertsService.error("Error", "No se han podido obtener los creditos, intenta nuevamente.");
        });
    },
    validarTipoAbono(esAbonoExtra) {
      if (esAbonoExtra) {
        this.infoAbono = this.parametros.tipos_abono_extra.find((elem) => elem.value === this.formPC.tipo_abono_extra);
      } else {
        this.infoAbono = null;
      }
    },
    realizarPago() {
      this.inhabilitarBtnPagar = true;
      this.formPC.url_retorno_comercio = this.parametros.url_retorno_comercio;
      this.formPC.url_notificacion_resultado = this.parametros.url_notificacion_resultado;
      this.formPC.tipo_abono_extra = this.abonoExtraV ? Number(this.formPC.tipo_abono_extra) : 3;

      PagosService.realizarPago(this.formPC).then((response) => {
        const data = response.data;
        let formulario = {};
        let formPago = document.getElementById("formPago");
        formPago.action = data.url_procesamiento;
        Object.keys(data.info_pago).forEach((key) => {
          formulario[key] = document.createElement("input");

          if (data.info_pago[key] === this.formPC.valor)
            formulario[key].value = parseFloat(data.info_pago[key]).toFixed(2);
          else formulario[key].value = data.info_pago[key];

          formulario[key].name = key;
          formPago.appendChild(formulario[key]);
        });
        formPago.submit();
      });
    },
  },
};
</script>

<style scoped>
.custom-card {
  height: 100%;
  min-height: 360px;
}

.c-puntos-pago {
  min-height: 282px;
}

.bg-custom-card {
  background-color: #f9cdd0d9;
}
</style>
