var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-row",
    [
      _vm.parametros !== null
        ? [
            _c("b-col", { attrs: { cols: "12" } }, [
              _c("p", { staticClass: "primary fs-24 fw-bold py-2" }, [
                _vm._v("Paga tu Cuota"),
              ]),
              _c("p", { staticClass: "fs-14 text-justify" }, [
                _vm._v(
                  " Elige el número de obligación y el valor por el cual deseas realizar el pago. Ten en cuenta que tus pagos se verán reflejados "
                ),
                _c("span", { staticClass: "fw-bold" }, [_vm._v("24 horas")]),
                _vm._v(
                  " después de aprobada la transacción. Tu pago debe estar entre " +
                    _vm._s(
                      _vm._f("currency")(_vm.parametros.tope_minimo_pago, {
                        fractionCount: 0,
                      })
                    ) +
                    " y " +
                    _vm._s(
                      _vm._f("currency")(_vm.parametros.tope_maximo_pago, {
                        fractionCount: 0,
                      })
                    ) +
                    " "
                ),
              ]),
            ]),
            _c(
              "b-col",
              { staticClass: "mb-4", attrs: { cols: "12", md: "6" } },
              [
                _c(
                  "b-card",
                  { staticClass: "custom-card" },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                attrs: {
                                  label: _vm.esPagoWeb
                                    ? "Tus Obligaciones"
                                    : "Número de Obligación",
                                },
                              },
                              [
                                _c(
                                  "b-form-select",
                                  {
                                    model: {
                                      value: _vm.creditoSelected,
                                      callback: function ($$v) {
                                        _vm.creditoSelected = $$v
                                      },
                                      expression: "creditoSelected",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-select-option",
                                      { attrs: { value: null } },
                                      [
                                        _vm._v(
                                          "Seleccione número de obligación"
                                        ),
                                      ]
                                    ),
                                    _vm.esPagoWeb
                                      ? _vm._l(
                                          _vm.$store.getters.creditosActivos,
                                          function (credito, idx) {
                                            return _c(
                                              "b-form-select-option",
                                              {
                                                key: credito.numeroObligacion,
                                                attrs: { value: credito },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "OBLIGACIÓN " + (idx + 1)
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        )
                                      : _vm._l(
                                          _vm.$store.getters.creditosActivos,
                                          function (credito) {
                                            return _c(
                                              "b-form-select-option",
                                              {
                                                key: credito.numeroObligacion,
                                                attrs: { value: credito },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      "Nro de Obligación " +
                                                        credito.numeroObligacion +
                                                        " - " +
                                                        credito.estado.toUpperCase()
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                  ],
                                  2
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm.creditoSelected !== null
                          ? _c(
                              "b-col",
                              { attrs: { cols: "12" } },
                              [
                                _c("b-form-group", {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "default",
                                        fn: function (ref) {
                                          var valorPagoGroup =
                                            ref.valorPagoGroup
                                          return [
                                            _c(
                                              "b-form-radio-group",
                                              {
                                                attrs: {
                                                  "aria-describedby":
                                                    valorPagoGroup,
                                                },
                                                model: {
                                                  value: _vm.esOtroValor,
                                                  callback: function ($$v) {
                                                    _vm.esOtroValor = $$v
                                                  },
                                                  expression: "esOtroValor",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "py-2 content-between-center fs-italic",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "primary fs-14 mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Saldo en Mora:"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", {
                                                          staticClass:
                                                            "flex-auto border-dashed mx-3 pt-2",
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fs-14 pr-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm
                                                                    .creditoSelected
                                                                    .saldoMora
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "py-2 content-between-center fs-italic",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass:
                                                              "primary fs-14 mb-0",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Próxima Cuota:"
                                                            ),
                                                          ]
                                                        ),
                                                        _c("span", {
                                                          staticClass:
                                                            "flex-auto border-dashed mx-3 pt-2",
                                                        }),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fs-14 pr-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm
                                                                    .creditoSelected
                                                                    .proximaCuota
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "py-2 content-between-center fw-bold",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-radio",
                                                          {
                                                            staticClass:
                                                              "primary fs-14",
                                                            attrs: { value: 1 },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Pago Mínimo:"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "label",
                                                          {
                                                            staticClass: "mb-0",
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "fs-14 pr-1",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm._f(
                                                                      "currency"
                                                                    )(
                                                                      _vm
                                                                        .creditoSelected
                                                                        .valorPago
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _c("i", {
                                                              staticClass:
                                                                "las la-question-circle fs-18 info cursor-pointer",
                                                              attrs: {
                                                                id: "popover-info",
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _c(
                                                          "b-popover",
                                                          {
                                                            attrs: {
                                                              target:
                                                                "popover-info",
                                                              placement:
                                                                "right",
                                                              triggers: "click",
                                                              "custom-class":
                                                                "bg-primary",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "strong",
                                                              {
                                                                staticClass:
                                                                  "text-white",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " Este Valor incluye el saldo en mora, seguros, valores que se hayan generado por alguna novedad en tu crédito y el valor de tu próxima cuota a vencer. "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "py-2 content-between-center fw-bold",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-radio",
                                                          {
                                                            staticClass:
                                                              "primary fs-14",
                                                            attrs: { value: 2 },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Pago Total:"
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "fs-14 pr-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm._f(
                                                                  "currency"
                                                                )(
                                                                  _vm
                                                                    .creditoSelected
                                                                    .saldoTotalFecha
                                                                )
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticClass:
                                                          "py-2 content-between-center fw-bold",
                                                        attrs: { cols: "12" },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-radio",
                                                          {
                                                            staticClass:
                                                              "primary fs-14",
                                                            attrs: { value: 3 },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Otro Valor:"
                                                            ),
                                                          ]
                                                        ),
                                                        _vm.esOtroValor === 3
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "pr-4",
                                                              },
                                                              [
                                                                _c("Money", {
                                                                  staticClass:
                                                                    "form-control text-right",
                                                                  on: {
                                                                    keypress:
                                                                      _vm.onlyNumber,
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.formPC
                                                                        .valor,
                                                                    callback:
                                                                      function (
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.formPC,
                                                                          "valor",
                                                                          $$v
                                                                        )
                                                                      },
                                                                    expression:
                                                                      "formPC.valor",
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    false,
                                    1568225333
                                  ),
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.esPagoWeb
                          ? _c(
                              "b-col",
                              {
                                staticClass: "content-end-center",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      variant: "danger",
                                      disabled:
                                        _vm.$v.$invalid ||
                                        _vm.inhabilitarBtnPagar,
                                    },
                                    on: { click: _vm.realizarPago },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "las la-dollar-sign",
                                    }),
                                    _vm._v(" Pagar "),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "b-col",
              { staticClass: "mb-4", attrs: { cols: "12", md: "6" } },
              [
                _c(
                  "b-card",
                  {
                    class:
                      "custom-card " + (_vm.esPagoWeb ? "bg-custom-card" : ""),
                  },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.esAbonoExtra && _vm.infoAbono === null
                          ? [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("p", { staticClass: "fs-14" }, [
                                    _vm._v(
                                      " Usted ha ingresado un monto mayor al pago mínimo. Selecciones que quiere hacer con el excedente. "
                                    ),
                                  ]),
                                  _c("b-form-group", {
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function (ref) {
                                            var tipoAbonoGroup =
                                              ref.tipoAbonoGroup
                                            return [
                                              _c(
                                                "b-form-radio-group",
                                                {
                                                  attrs: {
                                                    "aria-describedby":
                                                      tipoAbonoGroup,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.formPC
                                                        .tipo_abono_extra,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.formPC,
                                                        "tipo_abono_extra",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "formPC.tipo_abono_extra",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    _vm._l(
                                                      _vm.parametros
                                                        .tipos_abono_extra,
                                                      function (item, idx) {
                                                        return _c(
                                                          "b-col",
                                                          {
                                                            key: idx,
                                                            staticClass: "py-2",
                                                            attrs: {
                                                              cols: "12",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-radio",
                                                              {
                                                                staticClass:
                                                                  "primary fs-14",
                                                                attrs: {
                                                                  value:
                                                                    item.value,
                                                                },
                                                              },
                                                              [
                                                                _c("strong", [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.text
                                                                    )
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      item.info
                                                                    ) +
                                                                    " "
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      640768286
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "content-end-center",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "danger",
                                        disabled:
                                          _vm.formPC.tipo_abono_extra === null,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.validarTipoAbono(true)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "las la-check-circle",
                                      }),
                                      _vm._v(" Confirmar "),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : [
                              _c(
                                "b-col",
                                {
                                  staticClass: "c-puntos-pago fs-14",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm.abonoExtraV
                                    ? _c("p", { staticClass: "primary mb-0" }, [
                                        _vm._v(" Usted ha confirmado: "),
                                        _c("label", { staticClass: "w-100" }, [
                                          _c("strong", [
                                            _vm._v(_vm._s(_vm.infoAbono.text)),
                                          ]),
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.infoAbono.info) +
                                              " "
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _vm.esPagoWeb
                                    ? [
                                        _c("p", { staticClass: "fw-bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.mensajesMetodosPago.mensaje1
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "ul",
                                          { staticClass: "pl-4" },
                                          _vm._l(
                                            _vm.mensajesMetodosPago.mensaje2,
                                            function (item, idx) {
                                              return _c("li", {
                                                key: idx,
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.FmcMensaje
                                                  ),
                                                },
                                              })
                                            }
                                          ),
                                          0
                                        ),
                                        _c("p", { staticClass: "fw-bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.mensajesMetodosPago.mensaje3
                                            )
                                          ),
                                        ]),
                                        _c("p", {
                                          domProps: {
                                            innerHTML: _vm._s(
                                              _vm.mensajesMetodosPago.mensaje4
                                            ),
                                          },
                                        }),
                                        _c("p", { staticClass: "fw-bold" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.mensajesMetodosPago.mensaje5
                                            )
                                          ),
                                        ]),
                                        _c(
                                          "ul",
                                          { staticClass: "pl-4" },
                                          _vm._l(
                                            _vm.mensajesMetodosPago.mensaje6,
                                            function (item, idx) {
                                              return _c("li", {
                                                key: idx,
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    item.FmcMensaje
                                                  ),
                                                },
                                              })
                                            }
                                          ),
                                          0
                                        ),
                                      ]
                                    : _vm._e(),
                                  !_vm.esPagoWeb
                                    ? [
                                        _c("p", { staticClass: "fs-14" }, [
                                          _vm._v(
                                            "Correo electrónico donde desea ser notificado"
                                          ),
                                        ]),
                                        _c("b-form-group", {
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "default",
                                                fn: function (ref) {
                                                  var correoNotificacion =
                                                    ref.correoNotificacion
                                                  return [
                                                    _c(
                                                      "b-form-radio-group",
                                                      {
                                                        attrs: {
                                                          "aria-describedby":
                                                            correoNotificacion,
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.esOtroCorreo,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.esOtroCorreo =
                                                              $$v
                                                          },
                                                          expression:
                                                            "esOtroCorreo",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "py-2 content-between-center",
                                                                attrs: {
                                                                  cols: "12",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-radio",
                                                                  {
                                                                    staticClass:
                                                                      "primary fs-14",
                                                                    attrs: {
                                                                      value: 0,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Registrado:"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "py-2 content-end-center",
                                                                attrs: {
                                                                  cols: "12",
                                                                  lg: "8",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "fw-bold fs-14",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .$store
                                                                          .getters
                                                                          .correoElectronico
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "py-2 content-between-center",
                                                                attrs: {
                                                                  cols: "12",
                                                                  lg: "4",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "b-form-radio",
                                                                  {
                                                                    staticClass:
                                                                      "primary fs-14",
                                                                    attrs: {
                                                                      value: 1,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Otros correos:"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                            _c(
                                                              "b-col",
                                                              {
                                                                staticClass:
                                                                  "py-2",
                                                                attrs: {
                                                                  cols: "12",
                                                                  lg: "8",
                                                                },
                                                              },
                                                              [
                                                                _vm.esOtroCorreo ===
                                                                1
                                                                  ? [
                                                                      _c(
                                                                        "b-form-tags",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              size: "sm",
                                                                              "tag-pills":
                                                                                "",
                                                                              "tag-variant":
                                                                                "info",
                                                                              limitTagsText:
                                                                                "10",
                                                                              addButtonText:
                                                                                "Agregar",
                                                                              disableAddButton: true,
                                                                              placeholder:
                                                                                "Agregar correo",
                                                                              "tag-validator":
                                                                                _vm.validarCorreo,
                                                                              addButtonVariant:
                                                                                "outline-info",
                                                                              invalidTagText:
                                                                                "Correo invalido",
                                                                              duplicateTagText:
                                                                                "Correo repetido",
                                                                            },
                                                                          model:
                                                                            {
                                                                              value:
                                                                                _vm
                                                                                  .formPC
                                                                                  .correos_adicionales,
                                                                              callback:
                                                                                function (
                                                                                  $$v
                                                                                ) {
                                                                                  _vm.$set(
                                                                                    _vm.formPC,
                                                                                    "correos_adicionales",
                                                                                    $$v
                                                                                  )
                                                                                },
                                                                              expression:
                                                                                "formPC.correos_adicionales",
                                                                            },
                                                                        }
                                                                      ),
                                                                    ]
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            false,
                                            170192115
                                          ),
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "content-end-center",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm.abonoExtraV
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-3",
                                          attrs: { variant: "danger" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.validarTipoAbono(false)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "las la-arrow-left",
                                          }),
                                          _vm._v(" Atras "),
                                        ]
                                      )
                                    : _vm._e(),
                                  !_vm.esPagoWeb
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "danger",
                                            disabled:
                                              _vm.$v.$invalid ||
                                              _vm.inhabilitarBtnPagar,
                                          },
                                          on: { click: _vm.realizarPago },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "las la-dollar-sign",
                                          }),
                                          _vm._v(" Pagar "),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        : _vm._e(),
      _c("b-form", { attrs: { id: "formPago", method: "POST", hidden: "" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }